<template>
  <div class="page-container-boards text-white">
    <div v-if="jobboardsformatted.length > 0" class="top-header">
      <searchBar
        :placeholder="'Cerca per keywords...'"
        :search="filters.search"
        @update:search="filters.search = $event"
      />
    </div>
    <div v-if="loaded && getJobBoard.length === 0" class="no-job-boards-alert">
      <div class="no-job-boards-svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <title>alert</title>
          <path d="M13 14H11V9H13M13 18H11V16H13M1 21H23L12 2L1 21Z" />
        </svg>
      </div>
      <span class="no-job-boards-message">
        Nessuna job board trovata.
      </span>
    </div>
    <table
      v-for="board in getJobBoard"
      :id="'boarddiv_' + board.boardId"
      :key="board.board + '_' + board.open"
      class="panel-table"
      cellspacing="0"
      style="overflow: visible"
    >
      <thead :class="{ '--isOpen': board.open }" @click="openBoard(board)" @keydown.enter.prevent="">
        <th><span class="thTitle">{{ board.board }} </span></th>
        <th style="width: 155px;">
          <material-button
            class="jobboardBtn add"
            small
            text="Aggiungi keyword"
            @click.prevent="openAddDialog(board, $event)"
          />
        </th>
        <th class="th-chevron">
          <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
            <path fill="white" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
          </svg>
        </th>
      </thead>
      <tbody>
        <div class="table-container">
          <tr>
            <th style="cursor: pointer;" @click.prevent="changeOrder('id')">
              <span style="margin-right:5px">ID</span>
              <font-awesome-icon
                :class="{ active: filters.order == 'id' }"
                :icon="filters.asc ? 'arrow-down-1-9' : 'arrow-down-9-1'"
              />
            </th>
            <th style="cursor: pointer;" @click.prevent="changeOrder('keyword')">
              <span style="margin-right:5px">Keyword</span>
              <font-awesome-icon
                :class="{ active: filters.order == 'keyword' }"
                :icon="filters.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'"
              />
            </th>

            <th />
          </tr>
          <div class="scroll-table">
            <tr
              v-for="keyword in board.keywords"
              :id="keyword.id + '_' + keyword.newAdded ? 'new_' : '' + keyword.keyword.newAdded"
              :key="keyword.id + '_' + keyword.newAdded ? 'new_' : '' + keyword.keyword.newAdded"
              :class="{ 'recently-added': keyword.newAdded }"
            >
              <td>
                {{ keyword.id }}
              </td>
              <td>
                {{ keyword.keyword }}
              </td>
              <td>
                <material-button class="jobboardBtn delete" small text="Elimina keyword" @click="openDialog(keyword)" />
              </td>
            </tr>
          </div>
        </div>
      </tbody>
    </table>
  </div>
  <Dialog
    ref="addDialog"
    :round-icon="keyWord"
    :title="'Aggiungi keyword alla job &quot;' + elementToAdd?.board?.charAt(0).toUpperCase() + elementToAdd?.board?.slice(1) + '&quot;'"
    inputstringtitle="Inserisci la nuova keyword"
    :input="true"
    :bg-icon="true"
    :button-text="'Salva la nuova keywword'"
    @buttonAction="addKeyword"
  />

  <Dialog
    ref="deleteDialog"
    :round-icon="keyWordDelete"
    :success="'error'"
    title="Sei sicuro?"
    :subtitle="`Stai per cancellare la keyword <b>${elementToDelete?.keyword}</b> dalla jobboard <b>${elementToDelete.board}</b>`"
    :button-text="`Cancella ${elementToDelete?.keyword}`"
    @buttonAction="deleteKeyword"
  />
</template>

<script setup>
import Dialog from '../components/Dialog.vue'
import { inject, onBeforeMount, onMounted, computed, ref } from 'vue'
import { navbarStore } from '@/stores/navbar'
import searchBar from '../components/searchBarList.vue'
import { spinnerStore } from '@/stores/spinner'
import { sbapibackoffice } from '@/api'
const loaded = ref(false)
const navbar = navbarStore()
const spinner = spinnerStore()
const toast = inject('$toast')

const filters = ref({
  search: '',
  order: 'id',
  asc: true
})
const keyWord = require('../../static/keywordicon.png')
const keyWordDelete = require('../../static/deletekey.png')
const jobboards = ref([])
const jobboardsformatted = ref([])
const deleteDialog = ref(null)
const elementToAdd = ref({})
const addDialog = ref(null)
const elementToDelete = ref({})

onBeforeMount(async () => {
  spinner.show()
  try {
    const response = await sbapibackoffice.get('/jobboards')
    jobboards.value = response.data

    const keywordPromises = jobboards.value.map(async (board) => ({
      board: board.name,
      boardId: board.id,
      open: false,
      keywords: await fetchKeywords(board.id)
    }))

    jobboardsformatted.value = await Promise.all(keywordPromises)
  } catch (err) {
    jobboardsformatted.value = []
    const errorMessage = err.message || 'An error occurred while fetching jobboards.'
    toast.error('Impossibile scaricare le jobboards: ' + errorMessage)
  } finally {
    spinner.hide()
    loaded.value = true
    if (jobboardsformatted.value.length > 0) {
      jobboardsformatted.value[0].open = true
    }
  }
})

async function fetchKeywords(id) {
  const response = await sbapibackoffice.get(`/banned-keywords?id_job_board=${id}`)
  return response.data
}
const changeOrder = (sort) => {
  if (sort === filters.value.order) {
    filters.value.asc = !filters.value.asc
  } else {
    filters.value.asc = true
  }
  filters.value.order = sort
}
const getJobBoard = computed(() => {
  const filteredBoards = jobboardsformatted.value.map((board) => {
    const matchingKeywords = board.keywords.filter((keyword) =>
      keyword.keyword.toLowerCase().includes(filters.value.search.toLowerCase())
    )

    if (matchingKeywords.length > 0) {
      return { ...board, keywords: matchingKeywords }
    }

    return null
  }).filter((board) => board !== null)

  if (filters.value.order === 'id') {
    filteredBoards.forEach((board) => {
      board.keywords.sort((a, b) => (filters.value.asc ? a.id - b.id : b.id - a.id))
    })
  } else if (filters.value.order === 'keyword') {
    filteredBoards.forEach((board) => {
      board.keywords.sort((a, b) =>
        filters.value.asc
          ? a.keyword.localeCompare(b.keyword, undefined, { sensitivity: 'base' })
          : b.keyword.localeCompare(a.keyword, undefined, { sensitivity: 'base' })
      )
    })
  }

  return filteredBoards
})

const openDialog = (key) => {
  elementToDelete.value = key
  const board = jobboardsformatted.value.find((b) => b.boardId === key.id_job_board)
  if (board) {
    elementToDelete.value.board = board.board.charAt(0).toUpperCase() + board.board.slice(1)
  }
  deleteDialog.value.open()
}
const openAddDialog = (b, event) => {
  event.stopPropagation()
  elementToAdd.value = b
  addDialog.value.open()
}
const addKeyword = async (string) => {
  if (string?.text?.value) {
    spinner.show()
    try {
      const response = await sbapibackoffice.post('/banned-keywords', {
        keyword: string?.text?.value,
        idJobBoard: elementToAdd.value.boardId
      })
      if (response.status === 200) {
        const boardKeywords = jobboardsformatted.value.find(
          (b) => b.boardId === elementToAdd.value.boardId
        )
        if (boardKeywords) {
          const newKeyword = response.data
          newKeyword.newAdded = true
          boardKeywords.keywords.push(newKeyword)
          filters.value.order = 'id'
          filters.value.asc = false
          setTimeout(() => {
            jobboardsformatted.value.forEach((board) => {
              board.keywords.forEach((key) => {
                if (key.newAdded) {
                  key.newAdded = false
                }
              })
            })
          }, 5000)
        }

        toast.success('Keyword aggiunta!')
      } else {
        toast.error('Errore imprevisto:', response.status)
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorCode = error.response.data.messageCode
        if (errorCode === 'BK-001') {
          toast.error('idJobBoard mancante.')
        } else if (errorCode === 'BK-002') {
          toast.error('Non esiste nessuna job board con questo id.')
        } else {
          toast.error('Errore imprevisto.')
        }
      } else {
        toast.error('Errore imprevisto.')
      }
    } finally {
      elementToAdd.value = {}
      addDialog.value.close()
      spinner.hide()
    }
  }
}
const deleteKeyword = async () => {
  if (elementToDelete.value) {
    spinner.show()
    try {
      const response = await sbapibackoffice.delete('/banned-keywords?idBannerKeyWord=' + elementToDelete.value.id)
      if (response.status === 200) {
        const boardKeywords = jobboardsformatted.value.find(b => b.boardId === elementToDelete.value.id_job_board)

        if (boardKeywords) {
          const indexToDelete = boardKeywords.keywords.findIndex(item =>
            item.id === elementToDelete.value.id &&
            item.keyword === elementToDelete.value.keyword
          )

          if (indexToDelete !== -1) {
            boardKeywords.keywords.splice(indexToDelete, 1)
            console.log('Oggetto eliminato con successo dall\'array di keywords')
          } else {
            console.log('Oggetto non trovato nell\'array di keywords')
          }
        } else {
          console.log('Nessun oggetto trovato con il boardId specificato')
        }
        toast.success('Keyword cancellata!')

      } else {
        toast.error('Unexpected status:', response.status)
      }
    } catch (error) {
      if (error.response && error.response.status === 406) {
        if (error.response.data.messageCode === 'BK-003') {
          toast.error('Error BK-003: idBannerKeyWord is missing.')
        } else if (error.response.data.messageCode === 'BK-004') {
          toast.error('Error BK-004: No job board identified by this id.')
        }
      } else {
        toast.error('Unexpected error:', error)
      }
    } finally {
      deleteDialog.value.close()
      elementToDelete.value = {}
      spinner.hide()
    }
  }
}

const openBoard = (selectedBoard) => {
  jobboardsformatted.value = jobboardsformatted.value.map((board) => {
    if (board.boardId === selectedBoard.boardId) {
      return { ...board, open: !board.open }
    } else {
      return board
    }
  })

  if (selectedBoard.open) {
    // Scroll to the selected board's div
    document.getElementById('boarddiv_' + selectedBoard.boardId).scrollIntoView()
  }
}

onMounted(() => {
  navbar.title = 'Job Boards'
})
</script>

<style lang="scss">
* {
  scroll-behavior: smooth;
}

.no-job-boards-alert {
  background-color: rgba(185, 59, 71, .85);
  border-left: 8px solid var(--danger);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px;
  border-radius: 4px;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: white
    }
  }

  .no-job-boards-message {
    color: white;
    font-size: 16px;
    margin: 0 10px;
  }

  .no-job-boards-svg {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    background-color: #dc35688f;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.page-container-boards {
  height: calc(100% - 50px);
  width: 100%;
  font-family: "Lato", sans-serif;
  display: flex;
  flex-direction: column;
  padding: 40px;
  font-size: 14px;

  .table-container {
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.1);

    svg path {
      fill: rgba(255, 255, 255, .65)
    }

    svg.active path {
      fill: rgb(255, 255, 255);
    }
  }

  .scroll-table {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    max-height: 70vh;

    &::-webkit-scrollbar {
      width: 1px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
  }

  .top-header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
  }

  .th-chevron {
    text-align: right;
    width: 30px;
  }

  .collapsible-content {
    display: block;
    background-color: transparent;
  }

  table {
    color: white;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    thead {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding: 8px 5px 8px 15px;
      background: #1a74a3;
      top: 0;
      position: relative !important;
      display: flex;
      align-items: center;
      cursor: pointer;

      .thTitle {
        text-transform: capitalize;
        font-size: 15px;
      }

      th:first-of-type {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
      }

      th {
        padding: 0 !important;
        text-align: left;
        height: auto;
      }

      th.th-action {
        text-align: right;
        min-width: 30%;
      }

      th.th-chevron svg {
        transform: rotate(0deg);
        transition: transform 250ms ease-in-out;
      }

      +tbody {
        border-width: 0px 3px 3px;
        animation: slideOut 250ms ease-in;
        animation-fill-mode: forwards;
        overflow: hidden;
        display: block;
      }

      &.--isOpen {
        th.th-chevron svg {
          transform: rotate(90deg);
          transition: transform 250ms ease-in-out;
        }

        +tbody {
          border-color: #1a74a3;
          border-style: solid;
          animation: slideIn 300ms ease-in;
          animation-fill-mode: forwards;
        }
      }
    }

    .table-container {
      height: 100%;
      overflow: unset;

      th,
      td {
        vertical-align: middle !important;
        display: inherit;
        align-items: center !important;
      }

      td {
        //border: 1px solid yellow;
        text-align: left;
        padding: 8px 10px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        &:nth-child(2) {
          width: 100%;
          justify-content: flex-start;
        }

        &:nth-child(1) {
          width: 100px;
          justify-content: center;
        }

        &:nth-child(3) {
          min-width: 180px;
          //border: 1px solid yellow;
          padding-right: 20px;
          padding-left: 0;
          justify-content: flex-start;
        }
      }

      th {
        //border: 1px solid yellow;
        text-align: left;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        display: table-cell;
        background-color: transparent;
        font-weight: bold;
        position: sticky;
        top: 0;
        z-index: 1;
        padding: 10px;
        font-size: 14px;

        &:nth-child(2) {
          width: 100%;
        }

        &:nth-child(1) {
          width: 100px;
          text-align: center;
        }

        &:nth-child(3) {
          min-width: 180px;
          //border: 1px solid yellow;
          padding-right: 20px;
          padding-left: 0;
          text-align: left;
        }
      }

      tr {
        border-left: 4px solid transparent;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        background: transparent;
        transition: all 650ms ease-in-out;

        &.recently-added {
          border-left: 4px solid var(--accented);
          background: linear-gradient(90deg, rgba(66, 186, 222, 0.25) 30%, rgba(2, 0, 36, 0) 100%);
          transition: all 650ms ease-in-out;
        }

        td:first-child {
          padding-left: 4px !important;
        }
      }
    }

  }

  th,
  td {
    .jobboardBtn {
      text-align: center;
      outline: 0;
      border: 0;
      text-transform: uppercase;
      cursor: pointer;

      &.add {
        background: #1da3b8;

        &:hover {
          background: var(--info2);
        }
      }

      &.delete {
        background: #dc35466f;

        &:hover {
          background: var(--danger);
        }
      }
    }
  }
}

@keyframes slideIn {
  0% {
    height: 0;
    max-height: 0;
    opacity: 0;
  }

  50% {
    opacity: 0.75;
  }

  100% {
    height: auto;
    max-height: 70vh;
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    height: auto;
    max-height: 70vh;
    opacity: 1;
  }

  75% {
    opacity: 0.5;
  }

  100% {
    height: 0;
    max-height: 0;
    opacity: 0;
  }
}
</style>
